import React from "react" //, {useState} from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import Hero from "../../components/Hero/Hero"
//import TwoColumnGeneric from "../../components/Content/TwoColumnGeneric";
import SEO, { seoPropsMapping } from "../../components/seo"
import { withPreview } from "gatsby-source-prismic"

const Careers = ({ data }) => {
  const section = data.prismicCareersPage.data
  const jobs = data.allPrismicJobListing.group

  const seoProps = seoPropsMapping(section)

  return (
    <Layout>
      <SEO {...seoProps} />
      <Hero data={section} />
      <section className="uk-section default-theme">
        <div className={`uk-container`}>
          <div className="uk-hidden@m uk-flex uk-flex-center uk-margin-large">
            <button
              className="uk-position-relative uk-button uk-button-large uk-button-default uk-border-rounded"
              type="button"
            >
              <span
                className="uk-position-right uk-position-small"
                data-uk-icon="chevron-down"
                style={{ display: "flex" }}
              />
              <span
                className="uk-h5 uk-margin-remove-vertical uk-padding-remove uk-margin-right"
                style={{ color: "#fff" }}
              >
                Overview
              </span>
            </button>
            <div data-uk-dropdown="mode: click; pos: bottom" style={{ width: "calc(100% - 30px)" }}>
              <ul className="uk-nav uk-dropdown-nav" data-uk-accordion="multiple: false">
                {jobs.map((group, i) => (
                  <li
                    key={`category-${i}`}
                    className={`uk-background-muted uk-padding-small uk-border-rounded`}
                  >
                    <span id={`category-${i}`} className="uk-accordion-title">
                      {group.edges[0].node.data.job_role_category.document.data.category}
                    </span>
                    <ul className="uk-list uk-margin-small uk-margin-small-left uk-accordion-content">
                      {group.edges.map((job, i) => (
                        <li key={job.node.uid} className="uk-margin-small">
                          <Link
                            className="uk-link uk-link-reset"
                            to={`/company/careers/${job.node.uid}/`}
                          >
                            {job.node.data.role_title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="uk-grid-large" data-uk-grid>
            <div className="uk-width-1-3 uk-margin-xlarge-bottom uk-visible@m">
              <div className="careers-nav">
                <ul data-uk-accordion="multiple: false">
                  {jobs.map((group, i) => (
                    <li
                      key={`category-${i}`}
                      className={`uk-background-muted uk-padding-small uk-border-rounded`}
                      style={{ cursor: "pointer" }}
                    >
                      <span id={`category-${i}`} className="uk-accordion-title">
                        {group.edges[0].node.data.job_role_category.document.data.category}
                      </span>
                      <ul className="uk-list uk-margin-small-left uk-accordion-content">
                        {group.edges.map((job, i) => (
                          <li key={job.node.uid}>
                            <Link
                              className="uk-link uk-link-reset"
                              to={`/company/careers/${job.node.uid}/`}
                            >
                              {job.node.data.role_title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              className="prismic-content uk-width-expand uk-margin-xlarge-bottom"
              dangerouslySetInnerHTML={{ __html: section.copy.html }}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Careers)

export const careersQuery = graphql`
  {
    prismicCareersPage {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        page_title
        hero_title {
          html
        }
        hero_text_alignment
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        copy {
          html
          text
        }
      }
    }
    allPrismicJobListing {
      group(field: data___job_role_category___uid) {
        edges {
          node {
            uid
            data {
              role_title
              job_role_category {
                uid
                document {
                  ... on PrismicJobRoleCategory {
                    id
                    uid
                    data {
                      category
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
